import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { InvitationApiClient } from "../../infrastructure/MyDayClient/Invitations";
import Moment from "react-moment";

export class InvitationDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      authenticated: true,
      next: false,
    };
    this.cancel = this.cancel.bind(this);
  }

  async componentDidMount() {
    this.populateData(this.props.match.params.id);
  }

  render() {
    if (this.state.next) {
      return <Redirect to="/invitations" />;
    } else {
      return (
        <div>
          {this.state.data && (
            <>
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <h5>
                        <Link
                          to="/"
                          className="btn mr-2 btn-outline-dark mt-2 mb-2"
                        >
                          <FontAwesomeIcon icon={faChevronLeft} />
                        </Link>
                        {this.state.data && <span>Invitations</span>}
                        {this.state.loading && (
                          <Spinner
                            style={{
                              height: "18px",
                              width: "18px",
                              marginLeft: "10px",
                            }}
                            animation="border"
                          />
                        )}
                      </h5>
                    </Col>
                    <Col>
                      <Link
                        style={{ float: "right" }}
                        to={"/invitations/edit/" + this.state.data.id}
                        className="btn mr-2 btn-outline-dark mt-2 mb-2"
                      >
                        Edit
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <h6>Details</h6>
                      <hr />

                      <table>
                        <tbody>
                          <tr>
                            <td className="pr-2">Name:</td>
                            <td>
                              {this.state.data.name && this.state.data.name}
                              {!this.state.data.name && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Surname:</td>
                            <td>
                              {this.state.data.surname &&
                                this.state.data.surname}
                              {!this.state.data.surname && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Email:</td>
                            <td>
                              {this.state.data.email && this.state.data.email}
                              {!this.state.data.email && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Role:</td>
                            <td>
                              {this.state.data.role &&
                                this.state.data.role.name}
                              {!this.state.data.role && <i>None</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Team:</td>
                            <td>
                              {this.state.data.team &&
                                this.state.data.team.name}
                              {!this.state.data.team && <i>None</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Account:</td>
                            <td>
                              {this.state.data.account &&
                                this.state.data.account.companyName}
                              {!this.state.data.account && <i>None</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Portal Access:</td>
                            <td>
                              {this.state.data.hasCrmAccess && "Yes"}
                              {!this.state.data.hasCrmAccess && "No"}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Mobile Access:</td>
                            <td>
                                {this.state.data.hasMobileAccess && "Yes"}
                                {!this.state.data.hasMobileAccess && "No"}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Status:</td>
                            <td>
                              {this.state.data.status && this.state.data.status}
                              {!this.state.data.status && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Email status:</td>
                            <td>
                              {this.state.data.emailStatus &&
                                this.state.data.emailStatus}
                              {!this.state.data.emailStatus && (
                                <i>Not specified</i>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Invite code:</td>
                            <td>
                              {this.state.data.code && this.state.data.code}
                              {!this.state.data.code && <i>Not specified</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Accepted By:</td>
                            <td>
                              {this.state.data.user &&
                                this.state.data.user.name}
                              {!this.state.data.user && <i>Not accepted</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Expiry:</td>
                            <td>
                              {this.state.data.expiry && (
                                <Moment utc local format="ddd DD MMM, h:mm a">
                                  {this.state.data.expiry}
                                </Moment>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Used:</td>
                            <td>
                              {this.state.data.used && (
                                <Moment utc local format="ddd DD MMM, h:mm a">
                                  {this.state.data.used}
                                </Moment>
                              )}
                              {!this.state.data.used && <i>Not used</i>}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">Created:</td>
                            <td>
                              {this.state.data.created && (
                                <Moment utc local format="ddd DD MMM, h:mm a">
                                  {this.state.data.created}
                                </Moment>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="pr-2">User Type:</td>
                            <td>
                              {this.state.data.userType && (
                                <span>{this.state.data.userType?.name}</span>
                              )}
                              {!this.state.data.userType && (
                                <i>Not specified</i>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {this.state.data && this.state.data.status === "Pending" && (
                <div className="alert alert-primary">
                  <Row>
                    <Col>
                      <p className="mt-2 mb-2" style={{ lineHeight: "38px" }}>
                        If you do not want to give this person access any
                        longer, you can cancel the invitation before they accept
                        it.
                      </p>
                    </Col>
                    <Col md="2">
                      <Button
                        style={{ float: "right" }}
                        to="/invitations"
                        onClick={this.cancel}
                        className="btn mr-2 mt-2 mb-2 btn-info"
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </>
          )}
        </div>
      );
    }
  }

  async cancel() {
    var success = await InvitationApiClient.Cancel(this.props.match.params.id);
    if (success) {
      this.setState({ next: true });
    } else {
      this.setState({ deleteFail: true });
    }
  }

  async populateData(id) {
    if (!this.state.loading) {
      this.setState({ loading: true });

      var response = await InvitationApiClient.Get(id);

      console.log(response.data);

      if (!response.authenticated) {
        this.setState({
          authenticated: false,
          loading: false,
        });
      } else {
        this.setState({ data: response.data, loading: false });
      }
    }
  }
}
