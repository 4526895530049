import { ListQueryParams, ApiResponse } from "../MyDayAdminAPI";
import { GetAPIHeadersPatch, GetAPIDomain, GetAPIHeaders } from "../Helper";

export class InvitationQueryParams extends ListQueryParams {
  WithAccount(value: string) {
    this.WithParam("accountId", value);
    return this;
  }

  WithRole(value: string) {
    this.WithParam("roleId", value);
    return this;
  }

  WithTeam(value: string) {
    this.WithParam("teamId", value);
    return this;
  }

  WithStatus(value: string) {
    this.WithParam("status", value);
    return this;
  }
}

export class InvitationApiClient {
  static async List(params: InvitationQueryParams) {
    var query =
      (await GetAPIDomain()) + "/invitations" + params.GenerateQueryString();

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
      })
    );
  }

  static async Get(id: string) {
    return await ApiResponse.Create(
      await fetch((await GetAPIDomain()) + "/invitations/" + id, {
        headers: await GetAPIHeaders(),
      })
    );
  }

  static async Create(
    name: string,
    email: string,
    accountId: string,
    surname?: string,
      roleId?: string,
      hasCrmAccess?: boolean,
      hasMobileAccess?: boolean,
    teamId?: string,
    userTypeId?: string
  ) {
    return await ApiResponse.Create(
      await fetch((await GetAPIDomain()) + "/invitations/", {
        method: "post",
        headers: await GetAPIHeaders(),
        body: JSON.stringify({
          name: name,
          surname: surname,
            email: email,
            hasCrmAccess: hasCrmAccess == null ? false : hasCrmAccess,
            hasMobileAccess: hasMobileAccess == null ? false : hasMobileAccess,

          accountId: accountId,
          roleId: roleId,
          teamId: teamId,
          userTypeId: userTypeId,
        }),
      })
    );
  }

  static async Update(
    id: string,
    name: string,
    surname?: string,
      roleId?: string,
      hasCrmAccess?: boolean,
      hasMobileAccess?: boolean,
    userTypeId?: string
  ) {
    return await ApiResponse.Create(
      await fetch((await GetAPIDomain()) + "/invitations/" + id, {
        method: "PATCH",
        headers: await GetAPIHeadersPatch(),
        body: JSON.stringify([
          {
            op: "replace",
            path: "name",
            value: name,
          },
          {
            op: "replace",
            path: "surname",
            value: surname,
          },
          {
            op: "replace",
            path: "roleId",
            value: roleId,
            },
            {
                op: "replace",
                path: "hasCrmAccess",
                value: hasCrmAccess == null ? false : hasCrmAccess,
            },
            {
                op: "replace",
                path: "hasMobileAccess",
                value: hasMobileAccess == null ? false : hasMobileAccess,
            },
          {
            op: "replace",
            path: "userTypeId",
            value: userTypeId,
          },
        ]),
      })
    );
  }

  static async Cancel(id: string) {
    var query = (await GetAPIDomain()) + "/invitations/cancel/" + id;

    return await ApiResponse.Create(
      await fetch(query, {
        headers: await GetAPIHeaders(),
        method: "POST",
      })
    );
  }
}
